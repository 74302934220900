import React, { useEffect, useState, useRef } from "react"
import animateScrollTo from "animated-scroll-to";
import { isTablet, isMobile } from 'react-device-detect';
import Swiper from 'react-id-swiper';
import { useSelector } from 'react-redux';
import gtmEvent from "../../helpers/gtmEvent";
import { bind } from 'size-sensor';
interface props {
  stickyNavItems: [{
    stickyNavId: string
    stickyNavLabel: string
  }],
  isWebView?: boolean
}

let alertBarOffset = 0;
let itemOffsets = [];

export default function StickyNav({stickyNavItems, isWebView}: props) {
  const stickyNavRef = useRef(null);
  const [swiperEl, setSwiperEl] = useState(null);
  const [stickyNavOffset, setStickyNavOffset] = useState(0);
  const [stickyNavHeight, setStickyNavHeight] = useState(0);
  const [footerOffset, setFooterOffset] = useState(0);
  const [topNavHeight, setTopNavHeight] = useState(0);
  const [maxed, setMaxed] = useState(false);
  let currentWidth = typeof window !== "undefined" ? window?.innerWidth : 0;
  alertBarOffset = useSelector(state => state.siteState.alertBarOffset);
  let scrollEventListener = null;
  let stickyNavMounted = false;
  let activeNavId = '';
  let unbind = null;
  let threshold = 1920;
  const handleWindowResize = ()=> {
    if(typeof window !== "undefined"){
      currentWidth = window?.innerWidth;
    }              
    setMaxed(currentWidth >= threshold);
  }    
  handleWindowResize();
  useEffect(() => {              
    if(typeof window !== "undefined"){
      window.addEventListener('resize', handleWindowResize);
    }    
    return () => window.removeEventListener('resize', handleWindowResize)
  });
  useEffect(() => {
    if (stickyNavMounted == false) {
      startupOffsets();
      scrollEvent();
      stickyNavMounted = true;
      unbind = bind(document.getElementById('main-content'), () => {
        itemOffsets = getItemOffsets();
      });
    }
    return () => {
      window.removeEventListener('scroll', scrollEventListener);
      unbind();
    };
  }, [stickyNavOffset, stickyNavHeight, activeNavId]);

  function startupOffsets() {
    setStickyNavOffset(getOffset(stickyNavRef.current))
    setFooterOffset(getIdOffset(`footer`))
    setStickyNavHeight(stickyNavRef.current.clientHeight)
    itemOffsets = getItemOffsets();
    if (isTablet || isMobile) {
      setTopNavHeight(document.querySelector('.nav-mobile').clientHeight)
    } else {
      setTopNavHeight(document.querySelector('nav.nav').clientHeight)
    }
  }
  function alertBarOffsetMobile() {
    if (isMobile) {
      return alertBarOffset
    } else {
      return 0
    }
  }

  function getItemOffsets() {
    return stickyNavItems.map(item => {
      return ({
        offset: getIdOffset(item.stickyNavId),
        id: item.stickyNavId
      })
    });
  }

  function scrollToSection(id, buttonLabel) {
    let stickyEl = stickyNavRef.current;
    document.getElementById('main-content').focus()

    gtmEvent({
      'event': 'stickyNavInteraction',
      'location': stickyEl.classList.contains('sticky') ? 'Sticky Navigation' : 'Mid Page',
      'option': buttonLabel
    })

    let sectionOffset = getIdOffset(id);
    scrollTo(sectionOffset);
  }


  function getIdOffset(id: string): number {
    const idEl = document.getElementById(id);
    let elOffset = 0;
    
    if (idEl !== null) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      elOffset = idEl.getBoundingClientRect().top + scrollTop;
    }

    return elOffset
  }

  function getOffset(node): number {
    let elOffset = 0;
    
    if (node !== null) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      elOffset = node.getBoundingClientRect().top + scrollTop;
    }

    return elOffset
  }

  function scrollTo(offset: number): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let downOffset = stickyNavHeight; // height of sticky nav
    let upOffset = downOffset + topNavHeight; // height of sticky nav

    // When top nav hides while scrolling down, sets the correct offset
    let marginOffset = scrollTop < offset ? downOffset : upOffset;

    animateScrollTo(offset - marginOffset, {speed: 800})
  }


  const scrollEvent = () => {
    window.addEventListener('scroll', scrollEventListener = () => {
      stickyNavCheck(window.pageYOffset);
      
      window.requestAnimationFrame(() => {
        itemScrollHighlight(window.pageYOffset);
      })
    });  
  }

  const stickyNavCheck = (scroll) => {
    let stickyEl = stickyNavRef.current;
    
    if (scroll > (stickyNavOffset + alertBarOffsetMobile())) {
      stickyEl.classList.add('sticky')
      document.body.classList.add('sticky-nav-active')

      // if (scroll > (footerOffset + alertBarOffsetMobile())) {
      //   stickyEl.classList.add('footer-scroll')
      // } else {
      //   stickyEl.classList.remove('footer-scroll')
      // }

    } else {
      // Reset any active buttons
      let items = document.querySelectorAll('.sticky-nav__item');
      for (let button = 0; button < items.length; button++) {
        items[button].classList.remove("active");
      }
      activeNavId = 'reset';

      // On scroll up - top nav is visible so offset needs to account for that
      if (stickyEl.classList.contains('sticky') && scroll < (stickyNavOffset + alertBarOffsetMobile()) - topNavHeight) {
        stickyEl.classList.remove('sticky')
        stickyEl.classList.remove('footer-scroll')
        document.body.classList.remove('sticky-nav-active')
      }
    }
  }

  function itemScrollHighlight(scroll) {
    let stickyEl = stickyNavRef.current;
    let items = document.querySelectorAll('.sticky-nav__item');
    let offsetedScroll = 0;

    // console.log(itemOffsets);
    

    if (stickyEl.classList.contains('top-nav-hidden')) {
      offsetedScroll = (scroll + stickyNavHeight + 5) - alertBarOffsetMobile();
    } else {
      offsetedScroll = (scroll + stickyNavHeight + topNavHeight + 5) - alertBarOffsetMobile();
    }

    let filteredMenuItems = itemOffsets.filter((item) => {
      return item.offset < offsetedScroll
    })
    
    if (filteredMenuItems.length > 0) {
      // console.log(filteredMenuItems);
      
      let lastFilteredItem = filteredMenuItems[filteredMenuItems.length - 1];
      let navItemId = `sticky-nav__item-${lastFilteredItem.id}`;
      // console.log(navItemId !== activeNavId);
      
      if (navItemId !== activeNavId) {
        for (let button = 0; button < items.length; button++) {
          items[button].classList.remove("active");
        }

        let navItemEl = document.getElementById(navItemId);
        navItemEl.classList.add('active');

        for (let index = 0; index < swiperEl?.slides.length; index++) {
          const slide = swiperEl.slides[index];
          if (slide.id == `sticky-nav__item-${lastFilteredItem.id}`) {
            swiperEl.slideTo(index, 400);
          }
        }
        activeNavId = navItemId
      }
      
    }
  }
  

  const sliderConfig = {
    containerClass: `swiper-container`,
    freeMode: false,
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      enabled: true
    },
    on: {
      init: function (this) {
        setSwiperEl(this)
      },
    },
    breakpoints: {
      1024: {
        slidesPerView: 6,
      },
      768: {
        slidesPerView: 4,
      },
      0: {
    mousewheel: false,
        // slidesPerView: 'auto',
        slidesPerView: 3,
        // spaceBetween: 20,
      },
   },
  }
  return (
    <div className={`sticky-nav ${isWebView ? 'top-nav-hidden' : ''} ${maxed ? 'maxed' : ''}`} ref={stickyNavRef} data-datocms-noindex>
      <div className="inner-container">
        <Swiper {...sliderConfig}>
          {stickyNavItems.map((item, i) => {
            const id = item.stickyNavId;
            return (
              <button 
                className="sticky-nav__item" 
                id={`sticky-nav__item-${id}`}
                // data-hello={id}
                onClick={() => scrollToSection(id, item.stickyNavLabel)}
                key={`sticky-item-nav-${i}`}
              >
                {item.stickyNavLabel}
              </button>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}