import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AnimatedLink from './animatedLink';
import SmartLink from './smart-link';
import { hideOnScroll } from './../../helpers/hide-on-scroll';

interface props {
  link?: any
  text?: string
}

export default function CampaignNav({ link, text }: props) {
  const data = componentData();
  let navMounted = false;

  useEffect(() => {
    if (!navMounted) {
      // @ts-ignore
      hideOnScroll({ navbarSelector: '.nav', hidingClass: 'hide-nav', pollInterval: 600 });
      hideOnScroll({ navbarSelector: '.sticky-nav', hidingClass: 'top-nav-hidden', pollInterval: 600 });
      navMounted = true;
    }
    return () => {
    };
  })

  function skipToContent(): void {
    const mainEl = document.getElementsByTagName('main')[0];
    mainEl.focus();
  }

  return (
    <nav className={`nav nav--campaign`} data-datocms-noindex>
      <a
        href={``}
        onClick={e => e.preventDefault()}
        onKeyDown={e => e.keyCode === 13 ? skipToContent() : null}
        className="nav__skip"
        aria-label={`Skip to page content button`}
      >
        Skip to content
      </a>
      <div className="outer-container">
        <AnimatedLink
          to={'/'}
          className={`nav__logo`}
          ariaLabel={`Bankvic Logo - Button - Click to go back to the homepage`}
        >
          <img src={data.bankvicLogo.url} alt={data.bankvicLogo.alt} />
        </AnimatedLink>
        {link && text ?
          <SmartLink
            data={link}
            className="nav__campaign-button"
          >
            {text}
          </SmartLink>
          : null}
      </div>
    </nav>
  )
}

const componentData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsNav {
        bankvicLogo {
          url
          alt
        }
      }
    }
  `)
  return data
}