import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../layouts/seo"
import Footer from "../global/footer"
import Nav from '../global/nav';
import PageBody from '../body/page-body'
import Breadcrumbs from "../global/breadcrumbs"
import PageBanner from './../body/page-banner';
import StickyNav from '../global/sticky-nav';
import CampaignNav from "../global/campaign-nav"
import FacebookMetaTag from '../global/facebook-meta-tag';
import queryString from 'query-string';
import Mark from "mark.js";
import pluralize from "pluralize";
interface props {
  transitionStatus: string
  data?: any
}
let markTimeout;
let marked = false;
export default function TemplateBasePage({ transitionStatus, data }: props) {
  const { pageData } = data;
  const stickyNavComponents = pageData.components.filter(comp => comp !== null && comp.stickyNavLabel);

  //console.log(stickyNavComponents)
  const hasStickyNav = stickyNavComponents.length > 0;
  const hideMainFooter = pageData.hideMainFooter
  const includesFormaticForm = pageData.components.some(component => component.__typename === "DatoCmsForm");
  const [userAgent, setUA] = useState<any>(null);
  let keyword;
  

  
  useEffect(()=>{
    
    let { query } = queryString.parse(location.search);
    if(document.location.search.indexOf("?search-term=") > -1 && !query){
      query = decodeURI(document.location.search.split("=")[1]);
      keyword = query;
      
    }
   
    if(keyword && !marked){
      
      
      const markInstance = new Mark(document.querySelector("main"));
      clearTimeout(markTimeout);
      setTimeout(()=>{
        
      markInstance.unmark({
        
        done: () => {
          
          
            let modkey = pluralize.plural(keyword) === keyword ? pluralize.singular(keyword) : pluralize.plural(keyword);
            //markInstance.mark(modkey, {separateWordSearch:false}); 
            markInstance.mark(keyword, {separateWordSearch:false});
          
            let fullmatch = true;
            let mark = document.querySelector("main mark[data-markjs]"); //grab the first one                      
            if(mark?.childNodes.length){
                setTimeout(()=>{                                                   
                  mark?.scrollIntoView({block:"center", behavior:"smooth"})
                }, 1800);
               
            }else{
              fullmatch = false;
            }
            markInstance.mark(modkey, {separateWordSearch:true}); 
            markInstance.mark(keyword, {separateWordSearch:true});
            
            if(!fullmatch){
              console.log("no full match")
              
              mark = document.querySelector("mark[data-markjs]"); //grab the first one
              if(mark?.childNodes.length){
                if(!marked){
                  //let rect = mark?.getBoundingClientRect();
                  //console.log(mark);
                  
                  setTimeout(()=>{                
                    console.log(mark)
                    mark?.scrollIntoView({block:"center", behavior:"smooth"})
                  }, 1800);
                  marked = true;
                }
              }
            }
         
          
          
        }
      });
      }, 1000)
      marked = true;
      
    }
    let links = document.querySelectorAll("a");
    let basePath = document.location.origin+document.location.pathname;
    links.forEach((a)=>{
      let link = a.getAttribute("href");
      if(link && link?.indexOf("#") > -1){
        let split = link.split("#");
        let base = split[0];
        let anchor = split[1];
        if(base === basePath){ //only apply on the same page
          a.setAttribute("href", basePath+'#'+anchor);
          a.onclick = function(anchor, event){
              event.preventDefault();
              let target = document.querySelector(anchor);
              if(target){
                target.scrollIntoView({behavior:"smooth"});
              }                            
          }.bind(this, '#'+anchor)
        }        
      }      
    });
  });
  useEffect(() => {
    if(typeof window !== 'undefined'){
      setUA(navigator.userAgent)
    }
    
    
  },[]);
  //console.log(">>>", pageData.pageTitle, pageData.components);
  return (
    
    <>
      <div style="display:none" for-dato-crawler>
        {pageData.metaDescription}
      </div>
      <SEO
        title={pageData.pageTitle}
        metaDescription={pageData.metaDescription}
        ogDescription={pageData.ogDescription}
        ogTitle={pageData.ogTitle}
        ogImage={pageData.ogImage}
        noindex={pageData.noindex}
      />
      
      { pageData.campaignPage ?
        <CampaignNav link={pageData.campaignPageCtaLink} text={pageData.campaignPageCtaText} />
        :
        <Nav isWebView={userAgent ? false : true}/>
      }
      {
        pageData.slug === "home" ?
          <FacebookMetaTag />
          : null
      }
      
      <Layout
        className={`${pageData.slug} ${pageData.campaignPage ? 'campaign' : ''}`}
        transitionStatus={transitionStatus}
        campaignPage={pageData.campaignPage}
        isWebView={userAgent ? false : true}
      >
        {pageData.slug !== 'home' && !pageData.campaignPage ?
          <Breadcrumbs pageTitle={pageData.pageTitle} parents={pageData.treeParent} transitionStatus={transitionStatus} />
          : null}
        {pageData.pageBanner !== null &&
          <PageBanner component={pageData.pageBanner} />
        }
        {hasStickyNav && !pageData.campaignPage &&
          <StickyNav isWebView={userAgent ? false : true} stickyNavItems={stickyNavComponents} />
        }
        <div className="page-body" >
          <PageBody components={pageData.components} />
        </div>
        <Footer hideMainFooter={hideMainFooter} isWebView={userAgent ? false : true} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    pageData: datoCmsBasePage(slug: {eq: $slug}) {
      ...basePageFragment
      __typename
      components{
        __typename
      }
    }
  }
`