import React, { useState, useEffect } from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { parseHTML } from '../../helpers/parseHTML';
import { FluidObject } from 'gatsby-image';
import SmartLink from '../global/smart-link';
// @ts-ignore
import ShardBottom from '../../svg/shard-bottom.svg'
// @ts-ignore
import ShardTop from '../../svg/shard-top.svg'

interface props {
  data: {
    backgroundImage: {
      fluid: FluidObject
      alt: string
    }
    smartLink?: any
    auxText: string
    subText: string
    title: string
    linkText?: any
    hasCardBackground: boolean
  }
}
export default function notFoundBanner({data}: props) {
  const { 
    backgroundImage,
    auxText,
    title,
    subText,
    smartLink,
    linkText,
    hasCardBackground
  } = data;
  const [maxed, setMaxed] = useState(false);
  let threshold  = 1920;
  let currentWidth = typeof window !== "undefined" ? window?.innerWidth : 0;
  const handleWindowResize = ()=> {
    if(typeof window !== "undefined"){
      currentWidth = window?.innerWidth;
    }              
    setMaxed(currentWidth >= threshold);
    
  }    
  handleWindowResize();
  useEffect(() => {              
    if(typeof window !== "undefined"){
      window.addEventListener('resize', handleWindowResize);
    }    
    return () => window.removeEventListener('resize', handleWindowResize)
  });

  return (
    <div className={`notfound-banner ${hasCardBackground ? 'has-card' : ''} `} id="notfound-banner">
      <Img
        className={`notfound-banner__background ${maxed ? 'maxed' : ''}`}
        fluid={backgroundImage.fluid}
        objectFit="contain"
        objectPosition="50% 50%"
        alt={backgroundImage.alt}
      />

      <div className={`notfound-banner__shard`}>
        <ShardBottom className={`notfound-banner__shard-svg`}/>
        <ShardTop className={`notfound-banner__shard-svg mobile`}/>
      </div>

      <div className="inner-container">
        <div className={`notfound-banner__content`}>
          <p className={`notfound-banner__content-auxtext`}>{auxText}</p>
          <h1 className={`notfound-banner__content-title`}>{title}</h1>
          <p className={`notfound-banner__content-subtext`}>{subText}</p>
          {smartLink && linkText &&
            <SmartLink data={smartLink} className={`notfound-banner__content-link`}>
              {linkText}
            </SmartLink>
          }
        </div>
      </div>
    </div>
  )
}