import React from 'react';
import { Helmet } from 'react-helmet';

export default function FacebookMetaTag() {

  return (
    <Helmet>
      <meta name="facebook-domain-verification" content="q62f3tc6qt2rfvckbkogw42r4gdsxb" />
    </Helmet>
  )
}