import React, { useState, useEffect } from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { parseHTML } from '../../helpers/parseHTML';
import { FluidObject } from 'gatsby-image';
// @ts-ignore
import ShardBottom from '../../svg/shard-bottom.svg'

interface props {
  data: {
    backgroundImage: {
      fluid?: FluidObject
      alt: string
      url: string
    }
    subText: string
    title: string
    hasCardBackground: boolean
  }
}


export default function GraphicBanner({data}: props) {
  const { 
    backgroundImage,
    title,
    subText,
    hasCardBackground
  } = data;

  return (
    <div className={`graphic-banner ${hasCardBackground ? 'has-card' : ''}`} id="graphic-banner">
      {backgroundImage.fluid ? 
        <Img
          className={`graphic-banner__background`}
          fluid={backgroundImage.fluid}
          objectFit="contain"
          objectPosition="bottom right"
          alt={backgroundImage.alt}
        />
      :
        <img 
          className={`graphic-banner__background`}
          src={backgroundImage.url} 
          alt={backgroundImage.alt}
        />
      }

      <div className={`graphic-banner__shard`}>
        <ShardBottom className={`graphic-banner__shard-svg`}/>
      </div>

      <div className="inner-container">
        <div className={`graphic-banner__content`}>
          <h1 className={`graphic-banner__content-title`}>{parseHTML(title)}</h1>
          <p className={`graphic-banner__content-subtext`}>{subText}</p>
        </div>
      </div>
    </div>
  )
}