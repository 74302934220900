import React, { useState, useEffect } from 'react';
import AnimatedLink from '../global/animatedLink';
import Img from "gatsby-image/withIEPolyfill";
import { parseHTML } from './../../helpers/parseHTML';
import { FluidObject } from 'gatsby-image';
import animateScrollTo from 'animated-scroll-to';
import SmartLink from '../global/smart-link';
import ReactModal from 'react-modal';
import rateHelper from './../../helpers/rateHelper';
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'
// @ts-ignore
import ArrowDownGreyCircle from '../../svg/arrow-down-grey-circle.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'
// @ts-ignore
import ShardBottom from '../../svg/shard-bottom.svg'
// @ts-ignore
import ShardTop from '../../svg/shard-top.svg'

interface props {
  data: {
    backgroundImage: {
      fluid: FluidObject
      alt: string
      url: string
    }
    mobileBackgroundImage: {
      fluid: FluidObject
      alt: string
      url: string
    }
    hasArticleLink: boolean
    smartLink?: any
    articleText?: string
    articleImage?: {
      fluid: FluidObject
      alt: string
    }
    ctaLinks: [{
      linkText: string
      smartLink: any
    }]
    productVariant: any
    subtext: string
    subtitle: string
    title: string
    hasCampaignLabel: boolean
    campaignLabelText: string
    hasStickyNav: boolean
    hasCardBackground: boolean,
    smallVersion?:boolean,
    hideScrollMarker?:boolean
  }
}


export default function ProductBanner({data}: props) {
  const { 
    backgroundImage,
    mobileBackgroundImage,
    ctaLinks,
    productVariant,
    subtext,
    subtitle,
    title,
    smartLink,
    articleText,
    hasArticleLink,
    articleImage,
    hasCampaignLabel,
    campaignLabelText,
    hasCardBackground,
    hideScrollMarker,
    smallVersion
  } = data;
  
  const parentProduct = productVariant?.parentProduct;
  
  const [closeModalState, setCloseModalState] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [hasStickyNav, setHasStickyNav] = useState(false);
  const [maxed, setMaxed] = useState(false);
  let currentWidth = typeof window !== "undefined" ? window?.innerWidth : 0;
  const [bannerMounted, setBannerMounted] = useState(false);  
  
  const hasTerms = parentProduct && (
    parentProduct.productDisclosure.length > 0 ||
    productVariant.variantRateDisclaimer.length > 0 ||
    parentProduct.featureDisclaimer.length > 0 ||
    parentProduct.comparisonRateDisclaimer.length > 0);

  useEffect(() => {
    if (!bannerMounted) {
      if (document.querySelectorAll('.sticky-nav__item').length > 0) setHasStickyNav(true);
      setBannerMounted(true);
      currentWidth = window?.innerWidth;
    }
  }, []);

  function scrollDown(): void {
    var pbEl = document.getElementById("product-banner");
    animateScrollTo(pbEl.offsetTop + pbEl.scrollHeight, {speed: 2000})
  }


  function closeModal(){
    setCloseModalState(true)

    setTimeout(() => {
      setCloseModalState(false)
      setIsOpen(false);
      if (window.scrollY < 100) window.scrollTo(0,0)
    }, 500);
  }

  function openModal() {
    setIsOpen(true);
  }

  function getRateConversion(rate) {
    const {convertedRate, hasPercentage} = rateHelper(rate);
    if (hasPercentage) {
      return convertedRate
    } else {
      return rate
    }
  }
  let threshold  = 1920;
  
  const handleWindowResize = ()=> {
    if(typeof window !== "undefined"){
      currentWidth = window?.innerWidth;
    }              
    setMaxed(currentWidth >= threshold);
    
  }    
  handleWindowResize();
  useEffect(() => {              
    if(typeof window !== "undefined"){
      window.addEventListener('resize', handleWindowResize);
    }    
    return () => window.removeEventListener('resize', handleWindowResize)
  });
  return (
    <div 
      className={`product-banner 
        ${hasStickyNav ? 'has-sticky-nav' : ''} 
        ${hasCardBackground ? '' : 'no-background'} 
        ${hasCampaignLabel ? 'campaign' : ''}
        ${hasArticleLink ? 'has-article-link' : ''}
        ${smallVersion ? 'small-version' : ''}
        ${hideScrollMarker ? 'hide-scroll-marker' : ''}
        `
      } 
      id="product-banner"
    >
      <ReactModal
        className={`modal-product ${closeModalState && 'close'}`}
        overlayClassName={`modal-product__overlay ${closeModalState && 'close'}`}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={'Product card information'}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
      >
        <div className="modal-product__content">
          <button
            className="modal-product__close"
            onClick={closeModal}
          >
            <Close />
          </button>
          <p className="modal-product__title">{parentProduct?.productTitle}</p>
          {parentProduct?.productDisclosure && parentProduct?.productDisclosure.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(parentProduct?.productDisclosure)}</p>
          }
          {productVariant?.variantRateDisclaimer && productVariant?.variantRateDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(productVariant?.variantRateDisclaimer)}</p>
          }
          {parentProduct?.featureDisclaimer && parentProduct?.featureDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(parentProduct?.featureDisclaimer)}</p>
          }
          {parentProduct?.comparisonRateDisclaimer && parentProduct?.comparisonRateDisclaimer.length > 0 &&
            <p className="modal-product__disclaimer">{parseHTML(parentProduct?.comparisonRateDisclaimer)}</p>
          }
        </div>
      </ReactModal>
      {backgroundImage.fluid ? 
        <Img
          className={`product-banner__background ${mobileBackgroundImage ? 'has-mobile' : ''} ${maxed ? 'maxed' : ''} ${bannerMounted ? 'visible' : ''}` }
          fluid={backgroundImage.fluid}
          objectFit="cover"
          // objectPosition="50% 50%"
          objectPosition="top center"
          alt={backgroundImage.alt}
        />
      :
        <img 
          className={`product-banner__background svg ${mobileBackgroundImage ? 'has-mobile' : ''} ${bannerMounted ? 'visible' : ''}`}
          src={backgroundImage.url} 
          alt={backgroundImage.alt}
        />
      }
      {mobileBackgroundImage && mobileBackgroundImage?.fluid ? 
        <Img
          className={`product-banner__background mobile ${bannerMounted ? 'visible' : ''}`}
          fluid={mobileBackgroundImage.fluid}
          objectFit="cover"
          // objectPosition="50% 50%"
          objectPosition="top center"
          alt={mobileBackgroundImage.alt}
        />
      : mobileBackgroundImage ? 
        <img 
          className={`product-banner__background svg mobile ${bannerMounted ? 'visible' : ''}`}
          src={mobileBackgroundImage.url} 
          alt={mobileBackgroundImage.alt}
        /> : null
      }
      
      <div className={`product-banner__shard`}>
        <ShardBottom className={`product-banner__shard-svg`}/>
        <ShardTop className={`product-banner__shard-svg mobile`}/>
      </div>

      <div className="inner-container">
        {hasCampaignLabel && campaignLabelText.length > 0 &&
          <div className="product-banner__campaign-label">
            {campaignLabelText}
          </div>
        }
        <div className={`product-banner__content ${hasCardBackground ? '' : 'no-background'}`}>
          <h1 className={`product-banner__content-title`}>{parseHTML(title)}</h1>
          <p className={`product-banner__content-subtitle`}>{subtitle}</p>
          {productVariant !== null && productVariant.primaryRate !== "" ? 
            <div className="product-banner__rates">
              <div className="product-banner__rates-left rate">
                <p className="rate__number">{getRateConversion(productVariant.primaryRate)}</p>
                <span className="rate__percentage">%</span>
                <span className="rate__frequency">p.a</span>
                <p className="rate__header">{productVariant.primaryRateTitle}</p>
              </div>
              {productVariant.secondaryRate !== null && productVariant.secondaryRate.length > 0 ? 
                <div className="product-banner__rates-right rate">
                  <p className="rate__number">{getRateConversion(productVariant.secondaryRate)}</p>
                  <span className="rate__percentage">%</span>
                  <span className="rate__frequency">p.a</span>
                  <p className="rate__header">{productVariant.secondaryRateTitle}</p>
                </div>
              : null}
            </div>
          : null}
          {ctaLinks.length > 0 ? 
            <div className="product-banner__cta-links">
              {ctaLinks.map((link, index) => {
                return (
                  <SmartLink
                    key={`ctalink-${index}`}
                    data={link.smartLink}
                    className={`product-banner__cta-link`}
                  >
                    {link.linkText}
                    {index === 1 ? <ArrowRightBlue/> : null}
                  </SmartLink>
                )
              })}
            </div>
          : null}
          {subtext !== '' && hasTerms ? 
            <div className="product-banner__subtext">
              {parentProduct.termsConditionsText} 
              <a onClick={openModal} style={{cursor: 'pointer'}}> - see here</a>
            </div>
          : null}
        </div>
        {hasArticleLink !== false ?
        
          <SmartLink 
            className="product-banner__article-link article-link"
            data={smartLink}
          >
            <div className="article-link__blue-border"></div>
            <div className="article-link__content">
              {parseHTML(articleText)}
              <ArrowRightBlue/>
            </div>
            {articleImage !== null ? 
              <Img
                className={`article-link__image`}
                fluid={articleImage.fluid}
                objectFit="cover"
                // objectPosition="50% 50%"
                objectPosition="top center"
                alt={articleImage.alt}
              />
            : null}
          </SmartLink>
        : null}
        <ArrowDownGreyCircle
          className={`product-banner__scroll-down`}
          onClick={scrollDown}
        />
      </div>
    </div>
  )
}