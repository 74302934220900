import React, { useState, useEffect } from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { parseHTML } from '../../helpers/parseHTML';
import { FluidObject } from 'gatsby-image';
// @ts-ignore
import ShardBottom from '../../svg/shard-bottom.svg'
// @ts-ignore
import ShardTop from '../../svg/shard-top.svg'
interface props {
  data: {
    title: string
    subtitle?: string
    mobileBackgroundImage: {
      fluid?: FluidObject
      alt: string
      url: string
    }
    backgroundImage: {
      fluid?: FluidObject
      alt: string
      url: string
    }
    hasStickyNav: boolean
  }
}

export default function CampaignBanner({data: {
  title,
  subtitle,
  backgroundImage,
  mobileBackgroundImage
}}: props) {
  const [hasStickyNav, setHasStickyNav] = useState(false);  
  
  const [bannerMounted, setBannerMounted] = useState(false);  
  const [maxed, setMaxed] = useState(false);
  let threshold  = 1920;
  let currentWidth = typeof window !== "undefined" ? window?.innerWidth : 0;
  const handleWindowResize = ()=> {
    if(typeof window !== "undefined"){
      currentWidth = window?.innerWidth;
    }              
    setMaxed(currentWidth >= threshold);
    
  }    
  handleWindowResize();
  useEffect(() => {              
    if(typeof window !== "undefined"){
      window.addEventListener('resize', handleWindowResize);
    }    
    return () => window.removeEventListener('resize', handleWindowResize)
  });
  useEffect(() => {
    if (!bannerMounted) {
      if (document.querySelectorAll('.sticky-nav__item').length > 0) setHasStickyNav(true);
      setBannerMounted(true)
      
    }
  }, []);

  return (
    <header
      className={`campaign-banner ${hasStickyNav ? 'has-sticky-nav' : ''}`} 
      id="campaign-banner"
    >
      {backgroundImage.fluid ? 
        <Img
          className={`campaign-banner__background ${mobileBackgroundImage ? 'has-mobile' : ''} ${maxed ? 'maxed' : ''} ${bannerMounted ? 'visible' : ''}`}
          fluid={backgroundImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={backgroundImage.alt}
          
        />
      : null}
      {mobileBackgroundImage && mobileBackgroundImage.fluid ? 
        <Img
          className={`campaign-banner__background mobile ${bannerMounted ? 'visible' : ''}`}
          fluid={mobileBackgroundImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={mobileBackgroundImage.alt}
        />
      : null}

      <div className={`campaign-banner__shard`}>
        <ShardBottom className={`campaign-banner__shard-svg`}/>
        <ShardTop className={`campaign-banner__shard-svg mobile`}/>
      </div>

      <div className="outer-container">
        <div className="inner-container">
          <div className="campaign-banner__content">
            <h1 className="campaign-banner__content-title">{parseHTML(title)}</h1>
            {subtitle ?
             <p className="campaign-banner__content-subtitle">
               {subtitle}
             </p>
            : null}
          </div>
        </div>
      </div>
    </header>
  )
}